import * as React from 'react';
import { css } from '@emotion/core';

import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import IndexLayout from '../layouts';
import { inner, outer, SiteMain, SiteHeader } from '../styles/shared';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <IndexLayout>
    <Helmet>
      <title>プライバシーポリシー</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page">
              <h2>
                プライバシーポリシー
              </h2>
              <p>
                当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。
              </p>
              <p>
                このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。<br/>
                この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br/>
                この規約に関しての詳細は<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">Googleアナリティクスサービス利用規約</a>のページや
                <a href="https://policies.google.com/technologies/ads?hl=ja">Googleポリシーと規約</a>のページをご覧ください。
              </p>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default PrivacyPolicy;
